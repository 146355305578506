<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        <b>
          {{
            selectedItem.is_copy
              ? "COPY "
              : selectedItem.lbl_id
              ? "UPDATE "
              : "ADD "
          }}{{ masterInfo.lbl_cat.toUpperCase().replace(/_/g, " ") }}</b
        >
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>
    <b-form class="p-2" @submit.prevent="submitForm">
      <div v-if="showGroupButton" class="d-flex justify-content-center mb-1">
        <b-form-radio-group
          :disabled="selectedItem && selectedItem.lbl_id ? true : false"
          @input="onRadioChange"
          v-model="selectedFormValue"
          :options="optionsValues"
          name="radio-inline"
        ></b-form-radio-group>
      </div>

      <b-form-group
        v-if="masterInfo && masterInfo.lbl_name"
        :label="
          showGroupButton && selectedFormValue == 1
            ? 'Group Name'
            : masterInfo.lbl_name
        "
        label-for="lbl_name"
      >
        <b-form-input
          :disabled="
            !selectedItem.is_copy && selectedItem && selectedItem.lbl_id
              ? true
              : false
          "
          ref="focusMe"
          v-model="form.lbl_name"
          id="lbl_name"
          :placeholder="
            showGroupButton && selectedFormValue == 1
              ? 'Group Name'
              : masterInfo.lbl_name
          "
        />
      </b-form-group>

      <b-form-group
        label-for="lbl_grp"
        v-if="showGroupButton"
        :label="
          selectedFormValue == 1
            ? `Select ${masterInfo.lbl_cat.toUpperCase().replace(/_/g, ' ')}`
            : `Select Groups`
        "
      >
        <v-select
          placeholder="Name"
          label="lbl_name"
          :options="group_val_options"
          :reduce="(label) => label.lbl_id"
          multiple
          :close-on-select="false"
          v-model="form.group_val_ids"
        >
          <template #list-header>
            <div style="margin: 2px" class="text-center">
              <b-button-group size="sm">
                <b-button
                  variant="outline-success"
                  @click="
                    handleSelectAllClicked
                  "
                  >select all</b-button
                >
                <b-button
                  variant="outline-danger"
                  @click="
                    () => {
                      form.group_val_ids = [];
                    }
                  "
                  >unselect all</b-button
                >
              </b-button-group>
            </div>
            <hr />
          </template>
        </v-select>
      </b-form-group>

      <b-form-group
        v-if="masterInfo && masterInfo.lbl_value1"
        :label="masterInfo.lbl_value1"
        label-for="lbl_value1"
      >
        <v-select
          v-if="masterInfo.lbl_value1_input_type == 'select'"
          v-model="form.lbl_value1"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="masterInfo.lbl_value1_input_options"
          label="lbl_name"
          value="lbl_id"
          placeholder="Please Select"
        >
        </v-select>
        <b-form-input
          v-else
          v-model="form.lbl_value1"
          id="lbl_value1"
          :placeholder="masterInfo.lbl_value1"
        />
      </b-form-group>

      <b-form-group
        v-if="masterInfo && masterInfo.lbl_value2"
        :label="masterInfo.lbl_value2"
        label-for="lbl_value2"
      >
        <b-form-textarea
          v-if="masterInfo.lbl_value2_input_type == 'textarea'"
          id="lbl_value2"
          size="sm"
          v-model="form.lbl_value2"
        />

        <b-form-input
          v-else
          v-model="form.lbl_value2"
          id="lbl_value2"
          :placeholder="masterInfo.lbl_value2"
        />
      </b-form-group>
      <b-form-group
        v-if="masterInfo && masterInfo.lbl_value3"
        :label="masterInfo.lbl_value3"
        label-for="lbl_value3"
      >
        <b-form-textarea
          v-if="masterInfo.lbl_value3_input_type == 'textarea'"
          id="lbl_value3"
          size="sm"
          v-model="form.lbl_value3"
        />

        <b-form-input
          v-else
          v-model="form.lbl_value3"
          id="lbl_value3"
          :placeholder="masterInfo.lbl_value3"
        />
      </b-form-group>

      <b-form-group
        v-if="selectedItem.lbl_id"
        label="Sort order"
        label-for="order"
      >
        <b-form-input
          v-model="form.order"
          id="order"
          type="number"
          placeholder="Sort order"
        />
      </b-form-group>

      <!-- <b-form-group v-if="masterInfo && masterInfo.status" :label="masterInfo.status" label-for="status">
        <b-form-checkbox v-model="form.status" class="custom-control-primary" id="status" switch :unchecked-value="0">
        </b-form-checkbox>
      </b-form-group> -->

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mb-1 mt-3"
        block
      >
        Submit
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BFormRadio,
  BFormRadioGroup,
  BButtonGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { group_labels } from "@/utils/constants";
import { addData, updateData } from "@/apiServices/MastersServices";
import { sortedIndex } from "lodash";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ToastificationContentVue,
    BFormTextarea,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },

  props: {
    masterInfo: Object,
    selectedItem: Object,
    closeSidebar: Function,
    defaultSortOrder: Number,
  },

  data() {
    return {
      form: {
        lbl_cat: null,
        lbl_id: null,
        lbl_name: null,
        lbl_value1: null,
        lbl_value2: null,
        lbl_value3: null,
        revenue_upload_validation: null,
        status: null,
        order: null,
        group_val_ids: [],
      },
      selectedFormValue: "0",
      optionsValues: [],
    };
  },
  beforeMount() {
    // this.$store.dispatch("master/setLabelMaster", {
    //   key: this.masterInfo.lbl_cat,
    // });
  },
  computed: {
    showGroupButton() {
      return group_labels.includes(this.masterInfo.lbl_cat);
    },
    // group_val_options() {
    //   const lbl_cat = this.masterInfo.lbl_cat;
    //   const group_val_options = this.$store.state.master[lbl_cat].filter((i) =>
    //     this.selectedFormValue == 1 ? !i.lbl_is_group : i.lbl_is_group
    //   );
    //   return group_val_options;
    // },
    group_val_options() {
  const lbl_cat = this.masterInfo.lbl_cat;

  const group_val_options = this.$store.state.master[lbl_cat]
    .filter((i) => this.selectedFormValue == 1 ? !i.lbl_is_group : i.lbl_is_group)
    .sort((a, b) => {
      // Sorting alphabetically by 'lbl_name' (case-insensitive)
      return a.lbl_name.localeCompare(b.lbl_name);
    });
  return group_val_options;
},
     generateNewTempArray(){
        console.log(`The major array is ${this.group_val_options}`) ;
        console.log(`The minor array is ${this.form.group_val_ids}`) ; 
        const filteredCommonArray = this.group_val_options.filter((item) => this.form.group_val_ids.includes(item.lbl_id)) ;
        filteredCommonArray.sort((a, b) => a.lbl_name.localeCompare(b.lbl_name));
        const sortedIdArray = filteredCommonArray.map((obj) => obj.lbl_id) ;
        this.form.group_val_ids = sortedIdArray ;
        return sortedIdArray ;
     }
    // generateNewTempArray(arrayObject,array){
    //    const filteredCommonArray = arrayObject.filter((item) => array.includes(item.lbl_id)) ;
    //    filteredCommonArray.sort((a, b) => a.lbl_name.localeCompare(b.lbl_name));
    //    const sortedIdArray = filteredCommonArray.map((obj) => obj.lbl_id) ;
    //    this.form.group_val_ids = sortedIdArray ;
    //    return sortedIdArray ;
    // }
  },

  watch: {
    masterInfo() {
      if (this.masterInfo && group_labels.includes(this.masterInfo.lbl_cat)) {
        console.log("1");
        this.$store.dispatch("master/setLabelMaster", {
          key: this.masterInfo.lbl_cat,
        });
      }
    console.log(`The master info value ${this.masterInfo}`) ; 
    },
    selectedItem() {
      this.selectedFormValue = "0";
      this.optionsValues = [];
      this.optionsValues.push(
        {
          text: this.masterInfo.lbl_cat.toUpperCase().replace(/_/g, " "),
          value: "0",
        },
        {
          text:
            this.masterInfo.lbl_cat.toUpperCase().replace(/_/g, " ") + " GROUP",
          value: 1,
        }
      );

      if (this.masterInfo && this.selectedItem) {
        this.$nextTick(() => this.$refs.focusMe.$el.focus());

        this.form = {
          ...this.selectedItem,
          status: !this.selectedItem.lbl_id
            ? true
            : this.selectedItem.status == 1
            ? true
            : false,

          lbl_value3: this.masterInfo.hasOwnProperty("lbl_value3")
            ? this.selectedItem.revenue_upload_validation
            : "",
          revenue_upload_validation:
            this.selectedItem.revenue_upload_validation == 1 ? true : false,
          order:
            this.selectedItem.order && this.selectedItem.order != "999999999"
              ? this.selectedItem.order
              : null,
        };
        this.selectedFormValue = this.selectedItem.lbl_is_group
          ? this.selectedItem.lbl_is_group
          : "0";

        if (this.selectedItem.is_copy) {
          this.form.lbl_id = null;
          this.form.order = null;
        }
        if(this.selectedItem.group_val_ids && this.selectedItem.group_val_ids.length>0){
          // this.form.group_val_ids = this.generateNewTempArray(this.group_val_options,this.selectedItem.group_val_ids) ;
        }
        // this.form.group_val_ids = this.generateNewTempArray() ;
        console.log(this.form.group_val_ids,'gygfyf') ;
      }
    },
  },

  mounted() {
    // this.$nextTick(() => this.$refs.focusMe.$el.focus());
  },
  methods: {
 
    handleSelectAllClicked() {
  if (!this.group_val_options || this.group_val_options.length === 0) {
    console.warn('group_val_options is empty or undefined');
    return;
  }

  console.log(`Value fetched from Vue store is`, this.group_val_options);
  this.form.group_val_ids = this.group_val_options
    .slice() 
    .sort((a, b) => a.lbl_name.localeCompare(b.lbl_name)) 
    .map((o) => o.lbl_id); 

  console.log(`The selected ids are ${this.form.group_val_ids}`);

},

    onRadioChange() {
      //alert();
    },

     async submitForm() {
        // this.generateNewTempArray() ;
        let response = null;
        if (!this.form.lbl_id) {
          response = await addData(this.masterInfo.lbl_cat, {
            ...this.form,
            status: this.form.status == true ? 1 : 0,
            selectedFormValue: this.selectedFormValue,
            revenue_upload_validation: this.masterInfo.hasOwnProperty(
              "lbl_value3"
            )
              ? this.form.lbl_value3
              : this.form.revenue_upload_validation == true
              ? 1
              : 0,
          });
        } else {
          response = await updateData(
            this.masterInfo.lbl_cat,
            {
              ...this.form,
              selectedFormValue: this.selectedFormValue,
              status: this.form.status == true ? 1 : 0,
              revenue_upload_validation: this.masterInfo.hasOwnProperty(
                "lbl_value3"
              )
                ? this.form.lbl_value3
                : this.form.revenue_upload_validation == true
                ? 1
                : 0,
            },
            this.form.lbl_id
          );
        }

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.closeSidebar ? this.closeSidebar() : null;
          this.$emit("refreshList");
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "EditIcon",
              variant: "failure",
            },
          });
          this.closeSidebar ? this.closeSidebar() : null;
        }
        
      },
  },
};
</script>
