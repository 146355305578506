<template>
  <div>
    <b-card>
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-media no-body>
                <b-media-aside>
                  <b-link>
                    <b-avatar
                      v-if="form.image"
                      ref="previewEl"
                      :src="$FILES_URL + form.image"
                      size="90px"
                      rounded
                      icon="ImageIcon"
                    />
                    <b-avatar v-else size="90px" rounded text="IMG" />
                  </b-link>
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="$refs.refOrganizationImageInputEl.$el.click()"
                  >
                    Upload
                  </b-button>
                  <b-form-file
                    ref="refOrganizationImageInputEl"
                    accept=".jpg, .png, .gif"
                    :hidden="true"
                    plain
                    @change="uploadOrganizationImage"
                  />
                  <b-card-text
                    >Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text
                  >
                </b-media-body>
              </b-media>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Name">
              <b-form-input v-model="form.name" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Email">
              <b-form-input v-model="form.email" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Mobile Number">
              <b-form-input type="number" v-model="form.mobile_number" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Address">
              <quill-editor
                v-model="form.address"
                :options="{ theme: 'snow' }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Contact Person">
              <b-form-input v-model="form.contact_person" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Registration Number">
              <b-form-input type="number" v-model="form.registration_number" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Tax Type">
              <b-form-input v-model="form.tax_type" />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="4">
          <b-form-group label="Base Currency">
            <b-form-input v-model="form.base_currency" />
          </b-form-group>
        </b-col> -->
          <b-col cols="4">
            <b-form-group label="Tax Number">
              <b-form-input type="number" v-model="form.tax_number" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Base Currency">
              <b-form-input v-model="form.base_currency" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="License Period">
              <b-form-input type="number" v-model="form.license_period" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="User Limit">
              <b-form-input type="number" v-model="form.user_limit" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Contract Limit">
              <b-form-input type="number" v-model="form.contract_limit" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Notification before days">
              <b-form-input
                type="number"
                v-model="form.notification_before_days"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
          <b-button variant="primary" @click="saveOrganizationDetails"
            >Save</b-button
          >
        </b-col> -->
        </b-row>
      </b-form>
    </b-card>
    <b-col cols="12">
      <b-badge class="mb-1" variant="light-primary"> Bank Details </b-badge>
    </b-col>
    <b-form class="pl-1 pr-1">
      <b-row>
        <b-card
          style="border: 2px solid #818bbb"
          cols="12"
          v-for="(bankform, index) in bankforms"
          :key="index"
        >
          <b-col>
            <h5>Bank Details: {{ index + 1 }}</h5>
            <b-row>
              <b-col cols="4">
                <b-form-group label="Bank Name">
                  <b-form-input v-model="bankform.bankName" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Customer Name">
                  <b-form-input v-model="bankform.name" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Bank Address">
                  <b-form-input v-model="bankform.address" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Zip Code">
                  <b-form-input type="number" v-model="bankform.zipCode" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="City">
                  <b-form-input v-model="bankform.city" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="IBAN">
                  <b-form-input v-model="bankform.iban" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Country">
                  <b-form-input disabled value="CH" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Currency">
                  <b-form-input v-model="bankform.currency" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Account ID">
                  <b-form-input v-model="bankform.accountId" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Account Number">
                  <b-form-input v-model="bankform.accountNumber" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Swift Code">
                  <b-form-input v-model="bankform.swiftCode" />
                </b-form-group>
              </b-col>
              <b-col class="mt-2 " cols="2">
                <b-form-checkbox
                  v-model="bankform.isQrCode"
                  checked="bankform.isQrCode"
                  name="check-button"
                  switch
                >
                QrCode applicable
                </b-form-checkbox>
              </b-col>
              <b-col class="mt-2" cols="2">
                <b-form-checkbox
                  v-model="bankform.status"
                  checked="bankform.status"
                  name="check-button"
                  switch
                >
                Status
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-button
              class="mb-1"
              v-if="!bankform.acc_id"
              size="sm"
              variant="danger"
              @click="removeForm(index)"
              >Remove</b-button
            >
          </b-col>
        </b-card>
      </b-row>
      <b-row class="d-flex justify-content-between">
        <b-col class="mb-3" cols="2">
          <b-button
            class="sm"
            variant="primary"
            @click="saveOrganizationDetails"
            >Save</b-button
          >
        </b-col>
        <b-col class="mt-1" cols="6">
          <feather-icon
            size="25"
            style="cursor: pointer"
            icon="PlusCircleIcon"
            @click="addMore"
            v-b-tooltip.hover="'Add more'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BCardText,
  BMediaBody,
  BLink,
  BAvatar,
  BIcon,
  BTooltip,
  VBTooltip,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import * as OriganizationServices from "@/apiServices/OrganizationServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

import { uploadImages } from "@/utils/helpers";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    quillEditor,
    BButton,
    BFormFile,
    BMediaAside,
    BMedia,
    BCardText,
    BMediaBody,
    BLink,
    BAvatar,
    BIcon,
    BTooltip,
    BBadge,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      form: {
        image: "",
        name: "",
        email: "",
        mobile_number: "",
        address: "",
        logo: "",
        contact_person: "",
        registration_number: "",
        tax_type: "",
        base_currency: "",
        tax_number: "",
        license_period: "",
        user_limit: "",
        contract_limit: "",
        notification_before_days: "",
      },

      bankforms: [
        {
          name: "",
          acc_id: "",
          address: "",
          zipCode: "",
          city: "",
          iban: "",
          currency: "",
          accountId: "",
          accountNumber: "",
          swiftCode: "",
          status: true,
          bankName:"",
          isQrCode: true,
        },
      ],
    };
  },
  methods: {
    async uploadOrganizationImage(e) {
      const file = e.target.files[0];

      if (file.size > 800 * 1024) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "File size cannot be greater than 800kbs",
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }

      const fileUrl = await uploadImages(e);
      if (fileUrl && fileUrl.length) {
        this.form.image = fileUrl[0];
      }
    },

    async saveOrganizationDetails() {
      try {
        const invoiceAccounts = this.bankforms.map((bankform) => ({
          acId: bankform.currency || "",
          address: bankform.address || "",
          zip: bankform.zipCode || "",
          city: bankform.city || "",
          account: bankform.iban || "",
          country: bankform.country || "CH",
          currency: bankform.currency || "",
          name: bankform.name || "",
          acc_no: bankform.accountNumber || "",
          swift: bankform.swiftCode || "",
          status: bankform.status == true ? 1: 0,
          acc_id: bankform.acc_id || "",
          isQrCode:bankform.isQrCode == true ? 1 : 0,                                 //Checkbox
          bank: bankform.bankName,
        }));
        const payload = {
          ...this.form,
          invoiceAccounts,
        };
        const response = await OriganizationServices.saveOrganizationDetails(
          payload
        );
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Not Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.log("Error in saving organization ", err);
      }
    },
    async getOriganizationDetails() {
      try {
        const response = await OriganizationServices.getOrganizationDetails();
        // console.log(response.data);
        if (response.data.status) {
          this.form = {
            ...this.form,
            ...response.data.data,
          };
          this.bankforms =
            response.data.data.invoiceAccounts && response.data.data.invoiceAccounts.length > 0
            ? response.data.data.invoiceAccounts.map((account) => ({
                name: account.name || "",
                address: account.address || "",
                zipCode: account.zip || "",
                city: account.city || "",
                iban: account.account || "",
                country: account.country || "CH",
                currency: account.currency || "",
                accountId: account.currency || "",
                accountNumber: account.acc_no || 1,
                swiftCode: account.swift || "",
                acc_id: account.acc_id,
                status: account.status == 1 ? true : false,
                isQrCode: account.isQrCode == 1 ? true : false,
                bankName: account.bank || "",
              }))
            : [
                {
                  name: "",
                  address: "",
                  zipCode: "",
                  city: "",
                  iban: "",
                  country: "CH",
                  currency: "",
                  accountId: "",
                  accountNumber: 1,
                  swiftCode: "",
                  acc_id: null,
                  status: true,
                  isQrCode: true,
                  bankName: "",
                },
              ];
          // console.log(this.bankforms)
        }
      } catch (err) {
        console.log("Error in getting organization details ", err);
      }
    },
    async getInvoiceAccountsDetials() {},

    addMore() {
      this.bankforms.push({
        name: "",
        address: "",
        zipCode: "",
        city: "",
        iban: "",
        currency: "",
        accountId: "",
        accountNumber: "",
        swiftCode: "",
        status:true,
      });
      // console.log(this.bankforms);
    },
    removeForm(index) {
      this.bankforms.splice(index, 1);
    },
    // filteredBankForms() {
    //   return this.bankforms.filter(bankform => bankform.status);
    // }
  },
  computed: {
    isAccIdEmpty() {
      return this.bankform.acc_id.trim() === "";
    },
  },
  beforeMount() {
    this.getOriganizationDetails();
  },
};
</script>

<style></style>
