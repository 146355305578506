<template>
  <section>
    <b-sidebar
      title="Edit Bumper"
      id="bumper-edit-sidebar"
      v-model="showEditBumper"
      right
      shadow
    >
      <edit-bumpers :data="bumperData" @saved="onChange" />
    </b-sidebar>

    <!-- Filters Card -->
    <app-collapse
      class="collapse-group"
      accordion
      type="border"
      style="margin-bottom: 20px"
    >
      <app-collapse-item title="Filters">
        <b-row>
          <b-col sm="12" md="8" lg="8">
            <b-input-group class="input-group-merge mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Search"
                v-model="filters.search"
                @input="onSearch"
              />
            </b-input-group>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <v-select
              class="mb-1"
              v-model="filters.bumper"
              label="lbl_name"
              :reduce="(lbl) => lbl.lbl_id"
              :options="$store.state.master.bumper"
              aria-placeholder="Bumper"
              placeholder="Bumper"
              @input="onFiltersChange"
            />
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <v-select
              class="mb-1"
              v-model="filters.contract_nature"
              :options="contract_natures"
              aria-placeholder="Contract Nature"
              placeholder="Nature of Contract"
              @input="onFiltersChange"
            />
          </b-col>
          <b-col sm="12" md="4" lg="4">
            <v-select
              class="mb-1"
              v-model="filters.movie"
              :options="$store.state.master.movies"
              :filter-by="movieFilter"
              label="name"
              :reduce="(movie) => movie.id"
              placeholder="Movie"
              @input="onFiltersChange"
              @search="onSearchMovie"
            />
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <v-select
              class="mb-1"
              v-model="filters.vendor"
              :options="$store.state.master.vendors"
              label="company"
              :reduce="(vendor) => vendor.vendor_id"
              placeholder="Customer"
              @input="onFiltersChange"
              @search="onSearchCustomer"
            />
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <flat-pickr
              :config="{
                mode: 'range',
                ...$flatPickrConfig,
              }"
              placeholder="Date"
              class="form-control mb-1"
              v-model="filters.date"
              @input="onFiltersChange"
            />
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <flat-pickr
              :config="{
                mode: 'range',
                ...$flatPickrConfig,
              }"
              placeholder="Payment Date"
              class="form-control mb-1"
              v-model="filters.payment_date"
              @input="onFiltersChange"
            />
          </b-col>
          <!-- <b-col sm="6" md="2" lg="2">
            <b-button
              variant="primary"
              class="w-100 mb-1"
              @click="onChange"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              >Search</b-button
            >
          </b-col> -->
          <b-col sm="6" md="2" lg="2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="w-100 mb-1"
              @click="clearFilters"
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <b-row class="mb-2 mr-1">
      <!-- <b-col>
        <v-select
          @option:selected="onFiltersChange"
          visible
          v-model="pagination.limit"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="pagination.perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mb-1 mb-md-0"
        />
        <label class="mx-50">entries per page</label>
      </b-col> -->
      <b-col>
        <b-badge variant="info"
          >Total : {{ this.pagination.totalRows }}</b-badge
        >
      </b-col>

      <b-col class="d-flex align-items-end justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-info"
          @click="downloadCSV"
          >Download Bumpers</b-button
        >
      </b-col>
    </b-row>

    <!-- Table -->
    <b-card no-body v-if="$can('read', 'contract')">
      <b-table
        small
        bordered
        class="position-relative"
        primary-key="id"
        responsive
        :empty-text="tableEmptyText"
        show-empty
        :items="bumpersList"
        :fields="fields"
        hover
        @row-clicked="onRowClicked"
      >
        <!-- hover -->
        <template #cell(sr)="data">
          <div style="width: max-content">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
            <br />
            <b-badge variant="light-primary"
              >{{ `#C${data.item.contract_nature[0]}${data.item.contract_id}` }}
            </b-badge>
          </div>
        </template>
        <template #cell(titles)="data">
          <div style="width: max-content" v-html="data.value"></div>
        </template>
        <template #cell(amount_paid)="data">
          {{ formatCurrency(data.value) }}
        </template>
        <template #cell(amount)="data">
          {{ formatCurrency(data.value) }}
        </template>

        <template #cell(date)="data">
          <div style="width: max-content">
            {{ data.value ? moment(data.value).format("DD-MM-YYYY") : "" }}
          </div>
        </template>
        <template #cell(payment_date)="data">
          <div style="width: max-content">
            {{ data.value ? moment(data.value).format("DD-MM-YYYY") : "" }}
          </div>
        </template>

        <template #cell(actions)="data">
          <feather-icon
            v-if="$can('update', 'contract') && !data.item.deleted"
            size="16"
            icon="EditIcon"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            variant="outline-primary"
            title="Edit"
            class="mr-1 cursor-pointer"
            @click="editBumper(data.item)"
          />
        </template>
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
      </b-table>

      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                :limit="15"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BBadge,
  BTable,
  BCol,
  BRow,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BPagination,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BModal,
  VBTooltip,
  BSidebar,
  BFormCheckbox,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import EditBumpers from "./EditBumpers.vue";
import * as ContractServices from "@/apiServices/ContractServices";
import { contract_natures, labels, contract_statuses } from "@/utils/constants";
import { downloadFromURL, formatCurrency } from "@/utils/helpers";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { TokenService } from "@/apiServices/storageService";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BBadge,
    BTable,
    BCol,
    BRow,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BPagination,
    FeatherIcon,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BModal,
    vSelect,
    flatPickr,
    EditBumpers,
    BSidebar,
    BFormCheckbox,
  },
  data() {
    return {
      tableEmptyText: "LOADING...",

      contract_natures,
      contract_statuses,

      filters: {
        search: "",
        bumper: "",
        contract_nature: "",
        vendor: "",
        movie: "",
        date: "",
        payment_date: "",
      },

      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
        perPageOptions: [10, 25, 50, 100, "All"],
      },

      fields: [
        {
          key: "actions",
          label: "Actions",
          stickyColumn: true,
          variant: "primary",
        },
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "company",
          label: "Contact",
        },
        {
          key: "titles",
          label: "Movie",
        },
        {
          key: "bumper_name",
          label: "Name",
        },
        {
          key: "amount_paid",
          label: "Amount Paid",
        },
        {
          key: "amount",
          label: "Amount Payable",
        },
        {
          key: "date",
          label: "Date",
        },
        {
          key: "payment_date",
          label: "Payment Date",
        },
      ],
      bumpersList: [],
      bumperData: {},
      showEditBumper: false,
      selectedContractId: null,
      searchMovieTerm: "",
      searchCustomerTerm: "",
    };
  },
  methods: {
    onRowClicked(item, index, event) {
      if (this.$can("update", "contract") && !item.deleted) {
        this.editBumper(item);
      }
    },
    downloadCSV() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }

        let urlStr = `${process.env.VUE_APP_BASEURL}/contract/sheet/bumper/download?authorization=${token}`;

        downloadFromURL(urlStr, "Bumpers", "csv");
      } catch (error) {
        console.error(`Error in downloadCSV `, error);
      }
    },
    moment,
    formatCurrency,
    movieFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      // console.log("Filtering:", { option, search });
      return (
        option.name && option.name.toString().toLowerCase().indexOf(temp) > -1
      );
    },
    clearFilters() {
      this.filters = {
        search: "",
        status: "",
        contract_nature: "",
        vendor: "",
        movie: "",
        territories: [],
        rights: [],
        languages: [],
        from: "",
        to: "",
      };
      this.pagination = {
        ...this.pagination,
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      };
      this.onChange();
    },
    limitChanged() {
      this.pagination.currentPage = 1;
      this.onChange();
    },
    editBumper(data) {
      this.showEditBumper = true;
      this.bumperData = data;
    },
    async getBumpers() {
      const loader = this.$loading.show();

      try {
        const response = await ContractServices.getAllBumpers({
          limit: this.pagination.limit,
          page: this.pagination.currentPage,
          ...this.filters,
        });
        if (!response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          return;
        }

        this.bumpersList = response.data.data.bumpers;

        if (response.data.data.count) {
          this.pagination.totalRows = response.data.data.count;
        }
        if (!this.bumpersList.length) this.tableEmptyText = "NO RECORDS FOUND";
      } catch (error) {
        console.log("Error in getting bumpers List ", error);
        this.tableEmptyText = "Error in getting bumpers";
      } finally {
        loader.hide();
      }
    },

    onFiltersChange() {
      this.pagination.currentPage = 1;
      this.onChange();
    },

    onChange() {
      this.$nextTick(() => {
        this.getBumpers();
        localStorage.setItem(
          "filtersAndPaginationBumpers",
          JSON.stringify({
            filters: this.filters,
            pagination: this.pagination,
          })
        );
      });
    },

    onSearch() {
      if (this.debouce) clearTimeout(this.debouce);
      this.debouce = setTimeout(() => {
        this.onFiltersChange();
      }, 1000);
    },

    loadBumpersList() {
      const filtersAndPaginationBumpersStr = localStorage.getItem(
        "filtersAndPaginationBumpers"
      );
      if (filtersAndPaginationBumpersStr) {
        const obj = JSON.parse(filtersAndPaginationBumpersStr);
        this.pagination = obj.pagination;
        this.filters = obj.filters;
      }
      this.getBumpers();
    },
    onSearchMovie(searchMovieTerm) {
      return;
      this.searchMovieTerm = searchMovieTerm;
      this.$store.dispatch("master/setMoviesMaster", {
        search: searchMovieTerm,
      });
    },
    onSearchCustomer(searchCustomerTerm) {
      return;
      this.searchCustomerTerm = searchCustomerTerm;
      this.$store.dispatch("master/setVendorsMaster", {
        search: searchCustomerTerm,
      });
    },
  },
  mounted() {
    this.loadBumpersList();

    this.$store.dispatch("master/setVendorsMaster");
    this.$store.dispatch("master/setMoviesMaster");

    this.$store.dispatch("master/setLabelMaster", { key: labels.bumper });
  },
};
</script>

<style></style>
