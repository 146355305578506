export const labels = {
  type: 'type',
    genre: 'genre',
    age: 'age',
    language: 'language',
    director: 'director',
    cast: 'cast',
    country: 'country',
    image_format: 'image_format',
    sound_format: 'sound_format',
    aspect_ratio: 'aspect_ratio',
    label: 'label',
    media_type: 'media_type',
    distributor: 'distributor',
    pricing_code: 'pricing_code',
    contract_type: 'contract_type',
    revenue_share: 'revenue_share',
    currency: 'currency',
    tax: 'tax',
    revenue_types: 'revenue_types',
    contribution_masters: 'contribution_masters',
    milestone: 'milestone',
    bumper: 'bumper',

    contact_type: "contact_type",
    interest: "interest",

    rights_tree: 'rights_tree',
    territory_tree: 'territory_tree',

    rights: 'rights',
    sub_right: 'sub_right',

    territory: 'territory',
    sub_territory: 'sub_territory',
    category: "categorys",
    sub_category: "sub_category",

    file_type: 'file_type',

    material_physical: 'material_physical',
    material_digital: 'material_digital',
    material_language_version: 'material_language_version',
    language_booking_code: 'language_booking_code',
    depot_portal: 'depot_portal',
    show_code: 'show_code',
    pricing_method: 'pricing_method',
    screen_definition: 'screen_definition',
    categories: 'categories',
    release_movie_status:'release_movie_status',
};

export const group_labels = [
  labels.territory,
  labels.rights,
];

export const contract_natures = [
  "Acquisition",
  "Sales",
  // "Licensor Offer",
  // "Licensee Offer",
  // "Refused",
];

export const contract_natures_obj = {
  Acquisition: "Acquisition",
  Sales: "Sales",
  // "Licensor Offer": "Licensor Offer",
  // "Licensee Offer": "Licensee Offer",
  // Refused: "Refused",
};

export const contract_statuses = [
  { label: "Final", value: "final" },
  { label: "Draft", value: "draft" },
  { label: "Deleted", value: "deleted" },
];

export const statement_period = [
  { value: "1", label: "Monthly" },
  { value: "3", label: "Quaterly" },
  { value: "2", label: "Half Yearly" },
  { value: "4", label: "Yearly" },
];
