import {
  convertToIntArr,
  strNumToBoolean,
  convertToStrArr,
  getStringOrNull,
  getStrOneOrZero,
  getTrueOrFalse
} from "@/utils/helpers";

export function reduceContractFormData(data) {


  const form = {
    sub_contract: data.contract.sub_contract ? true : false,
    main_contract_id: data.contract.main_contract_id,
    contract: {
      contract_nature: data.contract.contract_nature,
      movie_id: convertToIntArr(data.contract.movie_id),
      vendor_id: data.contract.vendor_id,
      contract_type: data.contract.contract_type,
      offer_date: data.contract.offer_date,
      contract_date: data.contract.contract_date,
      contract_comment: data.contract.contract_comment,
      contract_begin: data.contract.contract_begin,
      contract_end: data.contract.contract_end,
      duration_year: data.contract.duration_year,
      duration_month: data.contract.duration_month,
      duration_comment: data.contract.duration_comment,
      nod: data.contract.nod,
      sellofperiod: data.contract.sellofperiod,
      number_of_run: data.contract.number_of_run,
      material_delivery: data.contract.material_delivery,
      extension_clause: strNumToBoolean(data.contract.extension_clause),
      extension_date: data.contract.extension_date,
      extension_clause_desc: data.contract.extension_clause_desc,
      revenue_share_type: data.contract.revenue_share_type,
      mgadvance: data.contract.mgadvance,
      c_currency: data.contract.c_currency,
      r_comment: data.contract.r_comment,
      pacap: data.contract.pacap,
      painternal: data.contract.painternal,
      padistribution: data.contract.padistribution,
      pabapproved: data.contract.pabapproved,
      assets: convertToStrArr(data.contract.assets),
      trl_combination: (function () { let trl_combination = convertToStrArr(data.contract.trl_combination); return trl_combination.length ? trl_combination : ["Right"] })(),
      languages: data.languages,
      subtitles: data.subtitles,
      is_unique_shares: getTrueOrFalse(data.contract.is_unique_shares)

      // deduction_from_rental_pa: getStrOneOrZero(data.contract.deduction_from_rental_pa),
      // deduction_from_rental_mg: getStrOneOrZero(data.contract.deduction_from_rental_mg),
      // distributor_share_status: getStrOneOrZero(data.contract.distributor_share_status),
      // distributor_share_mg_status: getStrOneOrZero(data.contract.distributor_share_mg_status),
      // distributor_share_mg_percent: getStringOrNull(data.contract.distributor_share_mg_percent),
      // distributor_commision_rental_status: getStrOneOrZero(data.contract.distributor_commision_rental_status),
      // distributor_commision_rental_percent: getStringOrNull(data.contract.distributor_commision_rental_percent),
      // licensor_share_status: getStrOneOrZero(data.contract.licensor_share_status),
      // licensor_share_mg_status: getStrOneOrZero(data.contract.licensor_share_mg_status),
      // licensor_share_recopmentPa: getStringOrNull(data.contract.licensor_share_recopmentPa),
      // licensor_share_mg_percent: getStringOrNull(data.contract.licensor_share_mg_percent),
    },
  };

  // TRLS --- Territory Rights Languages Subtitles
  if (data.trls && data.trls.length) {
    form.trls = data.trls.map((trls) => {
      return {
        territory: convertToIntArr(trls.territory),
        right: convertToIntArr(trls.right),
        language: convertToIntArr(trls.language),
        subtitle: convertToIntArr(trls.subtitle),
        exclusive: strNumToBoolean(trls.exclusive),
        comment: trls.comment,
      };
    });
  }

  // Milestones
  if (data.milestones && data.milestones.length) {
    form.milestoneList = data.milestones.map((milestone) => {
      return {
        ...milestone,
        milestone_id: parseInt(milestone.milestone_id),
        payable: milestone.payable,
        amountpayable: milestone.amountpayable,
        duedate: milestone.duedate,
        comment: milestone.comment,
      };
    });
  }

  // Bumpers
  if (data.bumpers && data.bumpers.length) {
    form.bumpers = data.bumpers.map((bumper) => {
      return {
        ...bumper,
        bumper: parseInt(bumper.bumper),
        amount: bumper.amount,
        date: bumper.date,
      };
    });
  }

  // Statements
  if (data.statement && data.statement.length) {
    form.contract_statement = data.statement.map((statement) => {
      return {
        period: statement.period,
        duration: statement.duration,
      };
    });
  }

  // Broadcast
  if (data.broadcast && data.broadcast.length) {
    form.contract_broadcast = data.broadcast.map((broadcast) => {
      return {
        date: broadcast.date,
        figures: broadcast.figures,
      };
    });
  }

  // Access
  if (data.access && data.access.length) {
    form.contract_access = data.access.map((access) => {
      return {
        language: access.language,
        access: access.access,
      };
    });
  }

  // Products
  if (data.products && data.products.length) {
    form.contract_products = data.products.map((product) => {
      return {
        product: product.product,
        price: product.price,
        currency: product.currency,
        flicensor: product.flicensor,
        licensor: product.licensor,
      };
    });
  }

  // Holdback
  if (data.hold_back && data.hold_back.length) {
    form.hold_back = data.hold_back.map((holdback) => {
      return {
        title: holdback.title,
        right: holdback.right ? holdback.right.split(',') : [],
        territory: holdback.territory ? holdback.territory.split(',') : [],
        comment: holdback.comment,
        start_date: holdback.start_date,
        end_date: holdback.end_date,
      };
    });
  }

  // Support Item
  if (data.support && data.support.length) {
    form.support_item = data.support.map((support) => {
      return {
        title: support.title,
        file: convertToStrArr(support.file),
        status1: strNumToBoolean(support.status1),
        date1: support.date1,
        date2: support.date2,
      };
    });
  }

  // Contract_approval
  if (data.contract_approval && data.contract_approval.length) {
    form.contract_approval = data.contract_approval.map((approval) => {
      return {
        ...approval,
        exclusive: approval.exclusive == "1" ? true : false,
        disabled: approval.status === 1 ? false : true,
      };
    });
  }

  return form;
}
